import { NgIf } from '@angular/common';
import { NgModule } from '@angular/core';
import { ToastDangerComponent } from './toast-danger.component';
import { IconModule } from '../icon/icon.module';

@NgModule({
  declarations: [ToastDangerComponent],
  imports: [IconModule, NgIf],
  exports: [ToastDangerComponent],
})
export class ToastDangerModule {}
