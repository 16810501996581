import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { DestroyRef, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import {
  EMPTY,
  Observable,
  Subject,
  catchError,
  concatMap,
  finalize,
} from 'rxjs';
import { environment } from '@environment';
import { AuthService } from '@services/auth.service';
import { CognitoAuthService } from '@services/cognito-auth.service';
import { JwtService } from '@services/jwt.service';
import { LocalStorageService } from '@services/local-storage.service';
import { WebsocketService } from '@services/websocket.service';
import { AuthServiceEnum } from '@shared/enums/auth-service.enum';
import { LocalStorageKeysEnum } from '@shared/enums/local-storage.enum';
import {
  CognitoRefreshTokenResponse,
  RefreshTokenResponse,
} from '@shared/types/auth.type';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
  /**
   * Endpoints que devem ser ignorados pelo interceptor
   */
  private readonly pathsToIgnore = ['/auth', '/auth/refresh-token'];

  /**
   * Requisições que devem ser recuperadas
   */
  private readonly _requestsToRetrieve = new Map<
    string,
    { request: HttpRequest<unknown>; request$: Subject<HttpRequest<unknown>> }
  >();

  private _refreshToken$ = new Subject<boolean>();
  private _updateTokenInProgress = false;

  constructor(
    private readonly _authService: AuthService,
    private readonly _cognitoAuthService: CognitoAuthService,
    private readonly _destroyRef: DestroyRef,
    private readonly _jwtService: JwtService,
    private readonly _localStorageService: LocalStorageService,
    private readonly _router: Router,
    private readonly _websocketService: WebsocketService,
  ) {
    if (environment?.cognito?.url) {
      this.pathsToIgnore.push(environment.cognito.url);
    }

    this._watchRequestsToBeRetrieved();
  }

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    if (this._isToRefreshToken(req)) return this._refreshToken(req, next);

    return next.handle(req);
  }

  private _watchRequestsToBeRetrieved() {
    this._refreshToken$
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(successToUpdateToken => {
        for (const [key, { request: req, request$: req$ }] of this
          ._requestsToRetrieve) {
          if (successToUpdateToken)
            req$.next(this._getRequestWithUpdatedHeaders(req));

          req$.complete();
          this._requestsToRetrieve.delete(key);
        }
      });
  }

  private _isToRefreshToken(req: HttpRequest<unknown>) {
    if (
      this.pathsToIgnore.some(
        path => req.url.replace(environment.apiUrl, '') === path,
      )
    )
      return false;

    const accessToken =
      (this._localStorageService.get(
        LocalStorageKeysEnum.ACCESS_TOKEN,
      ) as string) || '';

    const jwtDecoded = this._jwtService.decode(accessToken);

    if (!jwtDecoded) return false;

    const now = new Date().getTime();
    /**
     *  A expiração do token está sendo calculada com 10s a menos para tentar evitar
     * que um token expire após a validação da necessidade de chamar o endpoint de
     * atualizar o token de acesso.
     */
    const expiration =
      new Date(Number(jwtDecoded.exp) * 1000).getTime() - 10 * 1000;

    return now > expiration;
  }

  private _refreshToken(request: HttpRequest<unknown>, next: HttpHandler) {
    const request$ = new Subject<HttpRequest<unknown>>();

    this._requestsToRetrieve.set(request.url + '-' + Math.random() * 1000, {
      request,
      request$,
    });

    if (!this._updateTokenInProgress) this._updateToken();

    return request$.pipe(
      concatMap(request => {
        return next.handle(request);
      }),
    );
  }

  private _updateToken() {
    const refreshToken =
      (this._localStorageService.get(
        LocalStorageKeysEnum.REFRESH_TOKEN,
      ) as string) || '';

    this._updateTokenInProgress = true;

    const authServiceType = this._localStorageService.get(
      LocalStorageKeysEnum.AUTH_SERVICE_TYPE,
    );

    let request$: Observable<
      RefreshTokenResponse | CognitoRefreshTokenResponse
    >;

    if (authServiceType === AuthServiceEnum.O_AUTH) {
      request$ = this._authService.refreshToken(refreshToken);
    } else {
      request$ = this._cognitoAuthService.refreshToken(refreshToken);
    }

    request$
      .pipe(
        catchError(() => {
          this._authService.logout();
          this._router.navigate(['/login']);

          this._refreshToken$.next(false);

          return EMPTY;
        }),
        finalize(() => {
          this._updateTokenInProgress = false;
        }),
      )
      .subscribe(() => {
        this._websocketService.createNewClient();
        this._refreshToken$.next(true);
      });
  }

  private _getRequestWithUpdatedHeaders(
    req: HttpRequest<unknown>,
  ): HttpRequest<unknown> {
    const accessToken =
      (this._localStorageService.get(
        LocalStorageKeysEnum.ACCESS_TOKEN,
      ) as string) || '';

    return req.clone({
      setHeaders: {
        Authorization: 'Bearer ' + accessToken,
      },
    });
  }
}
