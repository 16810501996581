<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<div class="toast-danger d-flex bg-danger-light align-items-center gap-3 w-100">
  <app-icon
    class="text-danger"
    iconSize="1.25rem"
    [iconFamily]="config.icon?.family || defaultErrorConfig.icon.family"
    [iconName]="config.icon?.name || defaultErrorConfig.icon.name"
  />

  <div class="d-flex align-items-center justify-content-between gap-2 w-100">
    <div class="d-flex flex-column">
      <span
        class="d-inline-block custom-font fs-16px fw-600 lh-20px text-default"
        style="margin-bottom: 0.125rem"
      >
        {{ config.title || defaultErrorConfig.title }}
      </span>

      <span class="d-inline-block fs-14px fw-400 text-gray-3 lh-20px">
        {{ config.message }}
      </span>
    </div>

    <span
      *ngIf="config.reloadAction"
      class="cursor-pointer text-decoration-underline fw-500"
      (click)="reloadAction.emit()"
    >
      Atualizar página
    </span>

    <app-icon
      *ngIf="!config.reloadAction"
      class="text-gray-2 cursor-pointer"
      iconFamily="solid"
      iconName="close"
      iconSize="1.25rem"
      (click)="closeAction.emit()"
    />
  </div>
</div>
