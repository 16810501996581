<div
  class="toast-default d-flex align-items-center justify-content-between bg-toast gap-2 p-3 w-100"
>
  <span class="d-inline-block custom-font fs-16px fw-600 text-gray-0">
    {{ config.message }}
  </span>

  <app-icon
    class="text-gray-2 cursor-pointer"
    iconFamily="solid"
    iconName="close"
    iconSize="1.25rem"
    (click)="closeAction.emit()"
  />
</div>
