import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ToastConfig, ToastDefault } from '@shared/types/toast.type';

@Component({
  selector: 'app-toast-default',
  templateUrl: './toast-default.component.html',
  styleUrls: ['./toast-default.component.scss'],
})
export class ToastDefaultComponent {
  @Input({ required: true }) config!: ToastConfig & ToastDefault;

  @Output() closeAction = new EventEmitter<void>();
}
