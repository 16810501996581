/* eslint-disable max-len */
import { IEnvironment } from '@environments/environment.interface';

export const environment: IEnvironment = {
  company: {
    name: 'Vivo',
    logo: {
      default: 'profiles/vivo/assets/svg/logo.svg',
      login: 'profiles/vivo/assets/svg/login/vivo-logo.svg',
    },
  },
  production: false,
  cognito: {
    clientId: '4fppp2448fnp46ghnidh8lu25a',
    url: 'https://cognito-idp.us-east-2.amazonaws.com',
  },
  apiUrl: 'https://api.dev.vivo.regulatorio.tokenlab.dev',
  oAuth: {
    url: 'https://autenticainthml.redecorp.br/ms_oauth/oauth2/endpoints/vivooauthservice/authorize',
    responseType: 'code',
    clientId: '61103773484949a5902e667604b0b469',
    redirectUri: 'https://dev.vivo.regulatorio.tokenlab.dev/login',
    scope: 'TOKENLAB.Profile',
  },
};
