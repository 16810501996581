/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  RouteReuseStrategy,
  ActivatedRouteSnapshot,
  DetachedRouteHandle,
} from '@angular/router';

export class CustomRouteReuseStrategy implements RouteReuseStrategy {
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  store(
    route: ActivatedRouteSnapshot,
    handle: DetachedRouteHandle | null,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
  ): void {}

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    return null;
  }

  // Controla se devemos reutilizar uma rota já existente ou criar uma nova instância.
  shouldReuseRoute(
    future: ActivatedRouteSnapshot,
    current: ActivatedRouteSnapshot,
  ): boolean {
    // Verifica se a configuração da rota (routeConfig) é a mesma e se os parâmetros mudaram.
    // Se os parâmetros forem iguais, reutilize a rota.
    return (
      future.routeConfig === current.routeConfig &&
      future.params['id'] === current.params['id']
    );
  }
}
