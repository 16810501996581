import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ToastConfig, ToastDanger } from '@shared/types/toast.type';

@Component({
  selector: 'app-toast-danger',
  templateUrl: './toast-danger.component.html',
  styleUrls: ['./toast-danger.component.scss'],
})
export class ToastDangerComponent {
  @Input({ required: true }) config!: ToastConfig & ToastDanger;

  @Output() closeAction = new EventEmitter<void>();
  @Output() reloadAction = new EventEmitter<void>();

  readonly defaultErrorConfig: Required<
    Pick<ToastConfig & ToastDanger, 'icon' | 'title'>
  > = {
    title: 'Ocorreu um erro!',
    icon: {
      family: 'regular',
      name: 'circle-xmark',
    },
  };
}
