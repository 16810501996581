/* eslint-disable max-len */
import { ToastConfig } from '@shared/types/toast.type';

export const ToastErrorMessages = {
  FAILED_TO_ADD_ANALYST: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao adicionar o Analista',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  FAILED_TO_CREATE_PUBLIC_PROCESS: {
    type: 'DANGER',
    title: 'Ops! Link inválido ou SEI fora do ar',
    message: 'Por favor, verifique o link ou tente novamente mais tarde.',
  } as ToastConfig,
  FAILED_TO_DELELE_ANALYST: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao tentar excluir o Analista',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  FAILED_TO_DELELE_PROCESS: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao tentar excluir o Processo',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  FAILED_TO_DELELE_PROCESS_DUE_TO_FISCALIZATION: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao tentar excluir o Processo',
    message: 'Não é possível deletar um processo origem de uma fiscalização.',
  } as ToastConfig,
  FAILED_TO_LOAD_MORE_ITEMS: {
    type: 'DANGER',
    title: 'Erro ao carregar mais itens',
    message: 'Por favor, tente novamente ou recarregue a página.',
  } as ToastConfig,
  FAILED_TO_LOAD_PROCESS_DATA: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao carregar os dados do Processo',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  FAILED_TO_UPDATE_PROCESS_DATA: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao salvar os dados do Processo',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  FILE_FORMAT_NOT_ALLOWED: {
    type: 'DANGER',
    title: 'Ops! Um arquivo não permitido foi encontrado',
    message: 'Por favor, selecionar arquivos com formatos válidos',
  } as ToastConfig,
  PROCESS_NOT_FOUND: {
    type: 'DANGER',
    title: 'Ops! Processo não encontrado',
    message: 'O Processo não existe ou não pôde ser encontrado.',
  } as ToastConfig,
  FAILED_TO_LOAD_TASK_DETAILS: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao tentar visualizar os detalhes da tarefa.',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  USER_WITH_ACTIVE_TASK: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao tentar remover analista',
    message: 'Não é possível remover um analista com uma tarefa ativa.',
  } as ToastConfig,
  PERMISSION_DENIED: {
    type: 'DANGER',
    title: 'Ops! Acesso negado',
    message: 'Seu perfil não tem permissão para executar essa ação',
  } as ToastConfig,
  FAILED_TO_DELELE_USER: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao tentar remover o analista',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  FAILED_TO_LOAD_TASK_STATUS_TYPE: {
    type: 'DANGER',
    title:
      'Ops! Não foi possível carregar os status disponíveis para uma tarefa',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  FAILED_TO_OPEN_TASK_EDIT: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao abrir a edição da tarefa',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  FORBIDDEN: {
    type: 'DANGER',
    title: 'Ops! Você não pode acessar essa página',
    message: 'Usuário não autorizado.',
  } as ToastConfig,
  FAILED_TO_SEARCH_USERS: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao buscar os usuários',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  JUST_ONE_ANALYST_LEFT: {
    type: 'DANGER',
    title: 'Não é possível excluir o Analista da lista',
    message: 'Só há um Analista no momento. Para excluí-lo, adicione outro.',
  } as ToastConfig,
  JUST_ONE_ANALYST_RESPONSIBLE_LEFT: {
    type: 'DANGER',
    title: 'Não é possível excluir o Analista da lista',
    message:
      // eslint-disable-next-line max-len
      'Só há um Analista Responsável no momento. Para excluí-lo, adicione outro.',
  } as ToastConfig,
  ANALYST_WITH_ACTIVE_TASK: {
    type: 'DANGER',
    title: 'Ops! O analista possui uma tarefa ativa no momento',
    message: 'Não é possível excluir um analista com uma tarefa ativa.',
  } as ToastConfig,
  FAILED_TO_SEARCH_ANALYSTS: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao buscar os analistas',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  FAILED_TO_CREATE_TASK: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao adicionar a tarefa',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  FAILED_TO_EDIT_TASK: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao editar a tarefa',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  FAILED_TO_DELETE_TASK: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao excluír a tarefa',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  ANALYST_ALREADY_IN_PROCESS: {
    type: 'DANGER',
    title: 'Ops! Não foi possível adicionar analista',
    message: 'O analista selecionado já pertence ao processo.',
  } as ToastConfig,
  FAILED_UNMARK_ANALYST_AS_RESPONSIBLE: {
    type: 'DANGER',
    title: 'Ops! Não foi possível desmarcar como responsável',
    message:
      'Só há um Analista Responsável no momento. Para desmarcá-lo, adicione outro.',
  } as ToastConfig,
  FAILED_TO_PEFORM_ACTION: {
    type: 'DANGER',
    title: 'Ops! Não foi possível realizar a ação',
    message: 'Por favor, tente novamente.',
  } as ToastConfig,
  FAILED_TO_COPY_LETTER_NUMBER: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao copiar o N° da Carta',
    message: 'Por favor, tente novamente.',
  } as ToastConfig,
  FAILED_TO_COPY_TEXT: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao copiar o texto',
    message: 'Por favor, tente novamente.',
  } as ToastConfig,
  DOCUMENT_UNAVAILABLE: {
    type: 'DANGER',
    title: 'Ops! O documento ainda não está disponível',
    message: 'Tente novamente mais tarde ou atualize a página.',
  } as ToastConfig,
  FAILED_TO_OPEN_LETTER_LINK: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao abrir a vinculação de carta',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  FAILED_TO_VIEW_PROTOCOL: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao visualizar o protocolo',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  FAILED_TO_VIEW_FILE: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao visualizar o arquivo',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  FAILED_TO_LOAD_SENDERS_LETTERS: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao tentar carregar os remetentes',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  FAILED_TO_EDIT_DEADLINE: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao editar o ofício',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  FAILED_TO_LOAD_RECEIVERS_LETTERS: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao tentar carregar os destinatários',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  FAILED_TO_ADD_LETTER: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao gerar o Nº da Carta',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  FAILED_TO_DELETE_LETTER: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao tentar excluir a Carta',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  FAILED_UNLINK_LETTER: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao tentar desvincular a Carta',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  FAILED_VIEW_LETTER_DETAILS: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao visualizar os detalhes da Carta',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  FAILED_TO_LINK_LETTER: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao vincular a Carta',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  FAILED_TO_EDIT_LETTER_LINK: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao editar o vínculo da Carta',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  FAILED_TO_OPEN_EDIT_LETTER_LINK: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao abrir a edição da carta',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  FAILED_TO_GET_LETTER_FILES: (letterNumber: string): ToastConfig => ({
    type: 'DANGER',
    title: `Ops! Um erro ocorreu ao carregar os arquivos da Carta ${letterNumber}`,
    message: 'Por favor, tente selecionar novamente.',
  }),
  FAILED_TO_OPEN_INTIMATION: (intimationNumber: string): ToastConfig =>
    ({
      type: 'DANGER',
      title: `Ops! Um erro ocorreu ao abrir o Ofício "${intimationNumber}"`,
      message: 'Por favor, tente selecionar novamente.',
    }) as ToastConfig,
  FAILED_TO_APPLY_FLITER: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao aplicar o filtro',
    message: 'Por favor, tente novamente.',
  } as ToastConfig,
  FAILED_MARK_NOTIFICATION_READ: {
    type: 'DANGER',
    title: 'Ops! Não foi possível marcar como lido',
    message: 'Por favor, tente novamente',
  } as ToastConfig,
  FAILED_MARK_NOTIFICATION_NOT_READ: {
    type: 'DANGER',
    title: 'Ops! Não foi possível marcar como não lido',
    message: 'Por favor, tente novamente',
  } as ToastConfig,
  FAILED_TO_LOAD_SECTIONS: {
    type: 'DANGER',
    title: 'Ops! Não foi possível carregar as seções',
    message: 'Por favor, tente novamente',
  } as ToastConfig,
  FAILED_TO_ATTACH_FILES: {
    type: 'DANGER',
    title: 'Ops! Não foi possível anexar os arquivos',
    message: 'Por favor, tente novamente',
  } as ToastConfig,
  FAILED_TO_DELELE_FILE: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao tentar excluir o documento',
    message: 'Por favor, tente novamente.',
  } as ToastConfig,
  EDITION_NOT_FOUND: {
    type: 'DANGER',
    title: 'Ops! Edição não encontrada',
    message: 'A Edição não existe ou não pôde ser encontrada.',
  } as ToastConfig,
  FAILED_TO_LOAD_EDITION_DATA: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao carregar os dados da Edição',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  PUBLIC_CONSULTATION_NOT_FOUND: {
    type: 'DANGER',
    title: 'Ops! Consulta pública não encontrada',
    message: 'A Consulta pública não existe ou não pôde ser encontrada.',
  } as ToastConfig,
  FAILED_TO_LOAD_PUBLIC_CONSULTATION_DATA: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao carregar os dados da Consulta pública',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  FAILED_TO_LOAD_MORE_DOCUMENTS: {
    type: 'DANGER',
    title: 'Erro ao carregar mais Documentos',
    message: 'Por favor, tente novamente ou recarregue a página.',
  } as ToastConfig,
  FAILED_LOGIN_WITH_COGINTO: {
    type: 'DANGER',
    title: 'Erro ao realizar login',
    message: 'Por favor, tente novamente.',
  } as ToastConfig,
  FAILED_LOGIN_WITH_O_AUTH: {
    type: 'DANGER',
    title: 'Não foi possível entrar na sua conta',
    message: 'Algo deu errado. Tente novamente mais tarde.',
  } as ToastConfig,
  LETTER_NOT_FOUND: {
    type: 'DANGER',
    title: 'Ops! Carta não encontrada',
    message: 'A Carta não existe ou não pôde ser encontrada.',
  } as ToastConfig,
  FAILED_TO_REDIRECT_TO_CHAT: {
    type: 'DANGER',
    title: 'Erro ao redirecionar para o chat',
    message: 'Por favor, tente novamente.',
  } as ToastConfig,
  FAILED_TO_LOAD_MORE_CHATS: {
    type: 'DANGER',
    title: 'Erro ao carregar mais conversas',
    message: 'Por favor, tente novamente.',
  } as ToastConfig,
  FAILED_TO_LOAD_FILTER_CLASSIFICATIONS: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao carregar os filtros de classificação',
    message: 'Por favor, recarregue a página.',
  } as ToastConfig,
  FISCALIZATION_NOT_FOUND: {
    type: 'DANGER',
    title: 'Ops! Fiscalização não encontrada',
    message: 'A Fiscalização não existe ou não pôde ser encontrada.',
  } as ToastConfig,
  FAILED_TO_LOAD_FISCALIZATION_DATA: {
    type: 'DANGER',
    title:
      'Ops! Um erro ocorreu ao carregar os dados necessários para edição da Fiscalização',
    message: 'Por favor, recarregue a página.',
  } as ToastConfig,
  FAILED_TO_LOAD_FISCALIZATION_STATUS: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao carregar os status da Fiscalização',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  FAILED_TO_LOAD_INTERFERENCE_DATA: {
    type: 'DANGER',
    title:
      'Ops! Um erro ocorreu ao carregar os dados necessários para edição da Interferência',
    message: 'Por favor, recarregue a página.',
  } as ToastConfig,
  FAILED_TO_GET_LIKES: {
    type: 'DANGER',
    title: 'Erro ao carregar curtidas',
    message: 'Por favor, tente novamente.',
  } as ToastConfig,
  FAILED_TO_GET_MORE_LIKES: {
    type: 'DANGER',
    title: 'Erro ao carregar mais curtidas',
    message: 'Por favor, tente novamente.',
  } as ToastConfig,
  FAILED_TO_LIKE_COMMENT: {
    type: 'DANGER',
    title: 'Erro ao marcar o comentário como curtido',
    message: 'Por favor, tente novamente.',
  } as ToastConfig,
  FAILED_TO_UNLIKE_COMMENT: {
    type: 'DANGER',
    title: 'Erro ao desmarcar o comentário como curtido ',
    message: 'Por favor, tente novamente.',
  } as ToastConfig,
  FAILED_TO_LIKE_REPLY: {
    type: 'DANGER',
    title: 'Erro ao marcar a resposta como curtida',
    message: 'Por favor, tente novamente.',
  } as ToastConfig,
  FAILED_TO_UNLIKE_REPLY: {
    type: 'DANGER',
    title: 'Erro ao desmarcar a resposta como curtida',
    message: 'Por favor, tente novamente.',
  } as ToastConfig,
  FAILED_TO_LOAD_COMMENTS: {
    type: 'DANGER',
    title: 'Erro ao carregar comentários',
    message: 'Por favor, tente novamente.',
  } as ToastConfig,
  FAILED_TO_LOAD_MORE_COMMENTS: {
    type: 'DANGER',
    title: 'Erro ao carregar mais comentários',
    message: 'Por favor, tente novamente.',
  } as ToastConfig,
  FAILED_TO_LOAD_MORE_REPLIES: {
    type: 'DANGER',
    title: 'Erro ao carregar mais respostas',
    message: 'Por favor, tente novamente.',
  } as ToastConfig,
  FAILED_TO_LOAD_REPLIES: {
    type: 'DANGER',
    title: 'Erro ao carregar respostas',
    message: 'Por favor, tente novamente.',
  } as ToastConfig,
  FAILED_TO_ADD_REPLY: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao adicionar uma resposta',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  FAILED_TO_ADD_COMMENT: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao adicionar um comentário',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  FAILED_TO_LOAD_CITIES: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao carregar as cidades',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  FAILED_TO_ADD_CNPJ: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao adicionar um CNPJ',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  FAILED_TO_ADD_EXISTING_CNPJ: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao adicionar o CNPJ',
    message: 'Esse CNPJ já foi adicionado.',
  } as ToastConfig,
  ANATEL_CERTIFICATE_NOT_FOUND: {
    type: 'DANGER',
    title: 'Ops! Certidão Anatel não encontrada',
    message: 'A Certidão Anatel não existe ou não pôde ser encontrada.',
  } as ToastConfig,
  FAILED_TO_LOAD_ANATEL_CERTIFICATE_DATA: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao carregar os dados da Certidão Anatel',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  FAILED_TO_CHANGE_ANATEL_CERTIFICATE_STATUS: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao salvar os dados do CNPJ',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  FAILED_TO_UPDATE_ANATEL_CERTIFICATE_OBSERVATION: {
    type: 'DANGER',
    title:
      'Ops! Um erro ocorreu ao salvar a observação da Certidão Negativa Anatel',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  FAILED_TO_UPDATE_ANATEL_CERTIFICATE_ATTACHMENTS: {
    type: 'DANGER',
    title:
      'Ops! Um erro ocorreu ao salvar os arquivos da Certidão Negativa Anatel',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  FAILED_TO_REMOVE_ANATEL_CERTIFICATE_ATTACHMENTS: {
    type: 'DANGER',
    title:
      'Ops! Um erro ocorreu ao remover o anexo da Certidão Negativa Anatel',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  FAILED_TO_LOAD_ANATEL_CLEARANCE_CERTIFICATES: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao carregar a Certidão Negativa Anatel',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  ATTACHMENT_NOT_FOUND: {
    type: 'DANGER',
    title: 'Ops! Anexo não encontrado',
    message: 'O Anexo não existe ou não pôde ser encontrado.',
  } as ToastConfig,
  ANALYST_ALREADY_IN_ANATEL_CERTIFICATE: {
    type: 'DANGER',
    title: 'Ops! Não foi possível adicionar analista',
    message: 'O analista selecionado já pertence à Certidão Anatel.',
  } as ToastConfig,
  FAILED_TO_ADD_DOCUMENT: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao adicionar o documento',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  FAILED_TO_EDIT_DOCUMENT: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao editar o documento',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  FAILED_TO_DELETE_DOCUMENT: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao remover o documento',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  FAILED_TO_UPDATE_DESCRIPTION: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao atualizar a descrição',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  FAILED_TO_ADD_PUBLIC_CONSULTATION: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao adicionar a Consulta pública',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  FAILED_TO_UPDATE_PUBLIC_CONSULTATION_DATA: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao salvar os dados da Consulta pública',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  FAILED_TO_DELELE_PUBLIC_CONSULTATION: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao tentar excluir a Consulta pública',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  FAILED_TO_LOAD_PUBLIC_CONSULTATION_OPTIONS: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao carregar os dados da Consulta pública',
    message: 'Por favor, recarregue a página.',
  } as ToastConfig,
  FAILED_TO_EDIT_FISCALIZATION_DATA: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao editar os dados da Fiscalização',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  FAILED_TO_UPDATE_INTERFERENCE_DATA: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao atualizar os dados da Interferência',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  FAILED_TO_LOAD_INSPECTION_DATA: {
    type: 'DANGER',
    title:
      'Ops! Um erro ocorreu ao carregar os dados necessários para edição da Inspeção',
    message: 'Por favor, recarregue a página.',
  } as ToastConfig,
  FAILED_TO_UPDATE_INSPECTION_DATA: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao atualizar os dados da Inspeção',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  FAILED_TO_LOAD_OTHER_DEMAND_DATA: {
    type: 'DANGER',
    title:
      'Ops! Um erro ocorreu ao carregar os dados necessários para edição de Outras Demandas',
    message: 'Por favor, recarregue a página.',
  } as ToastConfig,
  FAILED_TO_UPDATE_OTHER_DEMAND_DATA: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao atualizar os dados de Outras Demandas',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  FAILED_TO_UPDATE_PROJECT_DATA: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao atualizar os dados de Projetos',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  FAILED_TO_ADD_SYSTEM: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao adicionar o sistema',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  FAILED_TO_UPDATE_SYSTEM: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao editar o sistema',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  FAILED_TO_DELETE_SYSTEM: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao excluir o sistema',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  ERROR: {
    type: 'DANGER',
    title: 'Erro!',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  FAILED_TO_EDIT_DEVICE: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao editar o aparelho',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  FAILED_TO_ADD_DEVICE: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao adicionar o aparelho',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  FAILED_TO_DELETE_DEVICE: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao remover o aparelho',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  FAILED_TO_ADD_SERVICE: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao adicionar o serviço',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  FAILED_TO_LOAD_SERVICE_DATA: {
    type: 'DANGER',
    title:
      'Ops! Um erro ocorreu ao carregar os dados necessários para adição do Serviço',
    message: 'Por favor, recarregue a página.',
  } as ToastConfig,
  FAILED_TO_LOAD_SERVICE_DATA_EDIT: {
    type: 'DANGER',
    title:
      'Ops! Um erro ocorreu ao carregar os dados necessários para edição do Serviço',
    message: 'Por favor, recarregue a página.',
  } as ToastConfig,
  FAILED_TO_EDIT_SERVICE: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao editar o serviço',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  FAILED_TO_DELETE_SERVICE: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao remover o serviço',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  FAILED_TO_OPEN_CREATE_TASK_MODAL: {
    type: 'DANGER',
    title:
      'Ops! Um erro ocorreu ao carregar os dados necessários para criar a Tarefa',
    message: 'Por favor, recarregue a página.',
  } as ToastConfig,
  FAILED_TO_CREATE_FISCALIZATION: {
    type: 'DANGER',
    title: 'Ops! Link inválido ou SEI fora do ar',
    message: 'Por favor, verifique o link ou tente novamente mais tarde.',
  } as ToastConfig,
  FAILED_TO_OPEN_EDIT_TASK_MODAL: {
    type: 'DANGER',
    title:
      'Ops! Um erro ocorreu ao carregar os dados necessários para editar a Tarefa',
    message: 'Por favor, recarregue a página.',
  } as ToastConfig,
  FAILED_TO_OPEN_EDIT_DEADLINE_MODAL: {
    type: 'DANGER',
    title:
      'Ops! Um erro ocorreu ao carregar os dados necessários para editar o ofício',
    message: 'Por favor, recarregue a página.',
  } as ToastConfig,
  FAILED_TO_EDIT_FISCALIZATION_DEADLINE: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao editar o ofício',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  FAILED_TO_CREATE_ACTION_PLAN_STATUS: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao adicionar o Status Plano de Ação',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  FAILED_TO_EDIT_ACTION_PLAN_STATUS: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao editar o Status Plano de Ação',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  FAILED_TO_DELETE_ACTION_PLAN_STATUS: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao remover o Status Plano de Ação',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  FAILED_TO_LOAD_PROJECT_DATA: {
    type: 'DANGER',
    title:
      'Ops! Um erro ocorreu ao carregar os dados necessários para edição de Projetos',
    message: 'Por favor, recarregue a página.',
  } as ToastConfig,
  FAILED_TO_LOAD_REQUEST_FOR_EXAMINATION_CREATE_DATA: {
    type: 'DANGER',
    title:
      'Ops! Um erro ocorreu ao carregar os dados necessários para adição do Pedido de Vista',
    message: 'Por favor, recarregue a página.',
  } as ToastConfig,
  FAILED_TO_LOAD_REQUEST_FOR_EXAMINATION_EDIT_DATA: {
    type: 'DANGER',
    title:
      'Ops! Um erro ocorreu ao carregar os dados necessários para edição do Pedido de Vista',
    message: 'Por favor, recarregue a página.',
  } as ToastConfig,
  FAILED_TO_CREATE_REQUEST_FOR_EXAMINATION: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao adicionar o Pedido de Vista',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  FAILED_TO_EDIT_REQUEST_FOR_EXAMINATION: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao editar o Pedido de Vista',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  FAILED_TO_LOAD_PROTOCOL_STATUS_CREATE_DATA: {
    type: 'DANGER',
    title:
      'Ops! Um erro ocorreu ao carregar os dados necessários para adição do Status',
    message: 'Por favor, recarregue a página.',
  } as ToastConfig,
  FAILED_TO_LOAD_PROTOCOL_STATUS_EDIT_DATA: {
    type: 'DANGER',
    title:
      'Ops! Um erro ocorreu ao carregar os dados necessários para edição do Status',
    message: 'Por favor, recarregue a página.',
  } as ToastConfig,
  FAILED_TO_CREATE_PROTOCOL_STATUS: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao adicionar o Status',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  FAILED_TO_EDIT_PROTOCOL_STATUS: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao editar o Status',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  FAILED_TO_LOAD_PROTOCOL_STATEMENT_CREATE_DATA: {
    type: 'DANGER',
    title:
      'Ops! Um erro ocorreu ao carregar os dados necessários para adição do Parecer',
    message: 'Por favor, recarregue a página.',
  } as ToastConfig,
  FAILED_TO_LOAD_PROTOCOL_STATEMENT_EDIT_DATA: {
    type: 'DANGER',
    title:
      'Ops! Um erro ocorreu ao carregar os dados necessários para edição do Parecer',
    message: 'Por favor, recarregue a página.',
  } as ToastConfig,
  FAILED_TO_CREATE_PROTOCOL_STATEMENT: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao adicionar o Parecer',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  FAILED_TO_EDIT_PROTOCOL_STATEMENT: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao editar o Parecer',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  FAILED_TO_LOAD_PROVISIONAL_FISCALIZATION_LIST: {
    type: 'DANGER',
    title:
      'Ops! Um erro ocorreu ao carregar a lista de Fiscalizações Provisórias',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  FAILED_TO_ASSOCIATE_PROVISIONAL_FISCALIZATION: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao associar à Fiscalização Provisória',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  FAILED_TO_DELELE_FISCALIZATION: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao tentar excluir a Fiscalização',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  FAILED_TO_DELELE_FISCALIZATION_DUE_TO_FISCALIZATION: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao tentar excluir a Fiscalização',
    message:
      'Não é possível deletar uma fiscalização que é pai de outra fiscalização.',
  } as ToastConfig,
  FAILED_TO_MIGRATE_ENTITY: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao realizar a migração',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  FAILED_TO_MIGRATE_ENTITY_PENDING_PROCESS: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao realizar a migração',
    message: 'O Processo está pendente. Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  FAILED_TO_MIGRATE_ENTITY_PENDING_FISCALIZATION: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao realizar a migração',
    message:
      'A Fiscalização está pendente. Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  FAILED_TO_GENERATE_PROVISIONAL_FISCALIZATION: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao gerar a Fiscalização Provisória',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
  FAILED_TO_GENERATE_REPORT: {
    type: 'DANGER',
    title: 'Ops! Um erro ocorreu ao gerar o relatório',
    message: 'Por favor, tente novamente mais tarde.',
  } as ToastConfig,
};
