import { Component, EventEmitter, Output } from '@angular/core';
import { ToastService } from '@services/toast.service';
import { ToastConfig } from '@shared/types/toast.type';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent {
  @Output() reloadAction = new EventEmitter<void>();

  private readonly defaultTimeout = 5 * 1000; // 5 segundos;
  private _timeoutId?: ReturnType<typeof setTimeout>;

  toast: ToastConfig = {
    type: 'DEFAULT',
    message: '',
  };

  isOpened = false;

  constructor(private readonly _toastService: ToastService) {
    this._toastService.watch.subscribe(config => {
      clearTimeout(this._timeoutId);

      this.toast = config;
      this.isOpened = true;

      this._timeoutId = setTimeout(
        () => (this.isOpened = false),
        config.timeout || this.defaultTimeout,
      );
    });
  }

  closeToast() {
    clearTimeout(this._timeoutId);
    this.isOpened = false;
  }

  reloadPage() {
    if (this.toast.reloadAction) this.toast.reloadAction();
    this.closeToast();
  }
}
