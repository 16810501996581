import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs';
import { environment } from '@environment';
import { AuthServiceEnum } from '@shared/enums/auth-service.enum';
import { LocalStorageKeysEnum } from '@shared/enums/local-storage.enum';
import { AuthResponse, RefreshTokenResponse } from '@shared/types/auth.type';
import { LocalStorageService } from './local-storage.service';
import { UserManagerService } from './user-manager.service';
import { WebsocketService } from './websocket.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly _endpoint = environment.apiUrl + '/auth';

  constructor(
    private readonly _http: HttpClient,
    private readonly _localStorageService: LocalStorageService,
    private readonly _router: Router,
    private readonly _userManagerService: UserManagerService,
  ) {}

  auth(code: string) {
    return this._http
      .post<AuthResponse>(this._endpoint, {
        accessCode: code,
        redirectUri: environment.oAuth.redirectUri,
      })
      .pipe(
        // eslint-disable-next-line camelcase
        tap(({ access_token, refresh_token, user }) => {
          this._localStorageService.set(
            LocalStorageKeysEnum.ACCESS_TOKEN,
            access_token,
          );

          this._localStorageService.set(
            LocalStorageKeysEnum.REFRESH_TOKEN,
            refresh_token,
          );

          this._localStorageService.set(LocalStorageKeysEnum.USER, user);
          this._localStorageService.set(
            LocalStorageKeysEnum.AUTH_SERVICE_TYPE,
            AuthServiceEnum.O_AUTH,
          );
        }),
        tap(({ user }) => {
          this._userManagerService.setUser({
            email: user.email,
            id: user.id,
            name: user.name,
            roles: user.userRole ? [user.userRole.userRoleType] : [],
          });
        }),
      );
  }

  refreshToken(refreshToken: string) {
    return this._http
      .post<RefreshTokenResponse>(this._endpoint + '/refresh-token', {
        refreshToken,
      })
      .pipe(
        // eslint-disable-next-line camelcase
        tap(({ access_token, refresh_token }) => {
          this._localStorageService.set(
            LocalStorageKeysEnum.ACCESS_TOKEN,
            access_token,
          );

          this._localStorageService.set(
            LocalStorageKeysEnum.REFRESH_TOKEN,
            refresh_token,
          );
        }),
        tap(({ user }) => {
          this._userManagerService.setUser({
            email: user.email,
            id: user.id,
            name: user.name,
            roles: user.userRole ? [user.userRole.userRoleType] : [],
          });
        }),
      );
  }

  logout() {
    this._localStorageService.remove(LocalStorageKeysEnum.AUTH_SERVICE_TYPE);
    this._userManagerService.clearUserData();

    WebsocketService.client.disconnect();
    this._router.navigate(['login']);
  }
}
