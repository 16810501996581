import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { ManagerOptions, Socket, SocketOptions, io } from 'socket.io-client';
import { environment } from '@environment';
import { LocalStorageKeysEnum } from '@shared/enums/local-storage.enum';
import { LocalStorageService } from './local-storage.service';

@Injectable({ providedIn: 'root' })
export class WebsocketService {
  /**
   *  Quando uma nova conexão é recebida e a conexão anterior for encerrada,
   * significa que estamos realmente criando uma nova conexão. Caso contrário, se
   * a nova conexão for recebida e a anterior não for encerrada, significa que estamos
   * realizando uma reconexão. (Obs! O 'connect' do socket é acionado em processos de
   * reconexão e conexão)
   */
  private static readonly _onConnect$ = new Subject<{
    isNewConnection: boolean;
    oldConnectionWasClosed: boolean;
  }>();
  private static readonly _onReconnect$ = new Subject<number>();
  private static readonly _onReconnecting$ = new Subject<boolean>();

  private _reconnectionClientId?: ReturnType<typeof setTimeout>;

  static client: Socket;

  constructor(private readonly _localStorageService: LocalStorageService) {}

  static get onConnect() {
    return WebsocketService._onConnect$.asObservable();
  }

  static get onReconnecting() {
    return WebsocketService._onReconnecting$.asObservable();
  }

  static get onReconnect() {
    return WebsocketService._onReconnect$.asObservable();
  }

  createNewClient() {
    let oldSocket: Socket | null = _.cloneDeep(WebsocketService.client) || null;

    WebsocketService.client = io(environment.apiUrl, this._websocketConfig);

    WebsocketService.client.on('connect', () => {
      if (oldSocket) {
        oldSocket.offAnyOutgoing();
        oldSocket.offAny();
        oldSocket.removeAllListeners();
        oldSocket.disconnect();

        oldSocket = null;

        WebsocketService._onConnect$.next({
          isNewConnection: true,
          oldConnectionWasClosed: true,
        });
      } else {
        WebsocketService._onConnect$.next({
          isNewConnection: true,
          oldConnectionWasClosed: false,
        });
      }

      WebsocketService._onReconnecting$.next(false);
    });

    WebsocketService.client.io.on('reconnect', (attempts: number) => {
      WebsocketService._onReconnect$.next(attempts);

      WebsocketService._onConnect$.next({
        isNewConnection: false,
        oldConnectionWasClosed: false,
      });

      WebsocketService._onReconnecting$.next(false);
    });

    WebsocketService.client.io.on('reconnect_attempt', () => {
      if (
        WebsocketService.client.active &&
        WebsocketService.client.disconnected
      ) {
        WebsocketService._onReconnecting$.next(true);
      }
    });

    WebsocketService.client.io.on('reconnect_failed', () => {
      this.createNewClient();
    });

    WebsocketService.client.on('disconnect', reason => {
      if (!WebsocketService.client.active) {
        if (reason === 'io server disconnect') {
          /**
           *  Se a reconexão for acionada em intervalos inferiores a 3s,
           * a tentativa anterior será ignorada até que não chegue uma nova tentativa
           * de reconexão antes dos 3s.
           */
          if (this._reconnectionClientId) {
            clearTimeout(this._reconnectionClientId);
            this._reconnectionClientId = undefined;
          }

          this._reconnectionClientId = setTimeout(() => {
            this.createNewClient();
            /**
             *  Após 3s um novo client será criado.
             */
          }, 3 * 1000);
        }
      }
    });

    WebsocketService.client.connect();
  }

  private get _websocketConfig(): Partial<ManagerOptions & SocketOptions> {
    const authToken = this._localStorageService.get(
      LocalStorageKeysEnum.ACCESS_TOKEN,
    );

    return {
      reconnection: true,
      autoConnect: false,
      reconnectionDelay: 5000,
      auth: {
        authorization: `Bearer ${authToken}`,
      },
    };
  }
}
