import { NgClass, NgIf, NgStyle } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconComponent } from './icon.component';

@NgModule({
  declarations: [IconComponent],
  imports: [NgClass, NgIf, NgStyle],
  exports: [IconComponent],
})
export class IconModule {}
