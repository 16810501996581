import {
  HttpErrorResponse,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, throwError } from 'rxjs';
import { environment } from '@environment';
import { AuthService } from '@services/auth.service';
import { LocalStorageService } from '@services/local-storage.service';
// eslint-disable-next-line import/namespace
import { LocalStorageKeysEnum } from '@shared/enums/local-storage.enum';

export const AuthTokenInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
) => {
  const localStorageService = inject(LocalStorageService);
  const authService = inject(AuthService);
  const router = inject(Router);

  const authToken = localStorageService.get(LocalStorageKeysEnum.ACCESS_TOKEN);
  const isApi = req.url.includes(environment.apiUrl);
  const pathsWithoutAuthToken = ['login'];

  let transformedReq: HttpRequest<unknown> = req;

  if (
    isApi &&
    !!authToken &&
    mustHaveAuthToken(req.url, pathsWithoutAuthToken)
  ) {
    transformedReq = req.clone({
      setHeaders: {
        authorization: `Bearer ${authToken}`,
      },
    });
  }

  /**
   *  A função 'handleUnauthorizedStatus' é utilizada para lidar com o status
   * UNAUTHORIZED da requisição e ela está nesse local para reaproveitar
   * a injeção de dependencia da função pai.
   */
  function handleUnauthorizedStatus(error: HttpErrorResponse) {
    /**
     *  Rotas do frontend que não devem aplicar o logout
     */
    const ignorePaths = ['/login'];
    const redirectTo = router.routerState.snapshot.url;

    if (
      /**
       *  O 'replace' remove as queryParams da rota
       */
      !ignorePaths.includes(redirectTo.replace(/\?.+/g, '')) &&
      error.status === HttpStatusCode.Unauthorized
    ) {
      authService.logout();
      router.navigate(['login'], { queryParams: { redirectTo } });
    }
  }

  return next(transformedReq).pipe(
    catchError((error: HttpErrorResponse) => {
      const errorHandler: Partial<
        Record<number, (error: HttpErrorResponse) => void>
      > = {
        [HttpStatusCode.Unauthorized]: handleUnauthorizedStatus,
      };

      if (errorHandler[error.status]) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        errorHandler[error.status]!(error);
      }

      return throwError(() => error);
    }),
  );
};

function mustHaveAuthToken(path: string, pathsWithoutAuthToken: string[]) {
  for (const pathWithoutAuthToken of pathsWithoutAuthToken) {
    if (path.includes(pathWithoutAuthToken)) return false;
  }

  return true;
}
