import { DestroyRef, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import {
  catchError,
  debounceTime,
  EMPTY,
  finalize,
  Observable,
  of,
  Subject,
  switchMap,
  tap,
} from 'rxjs';
import { AuthServiceEnum } from '@shared/enums/auth-service.enum';
import { LocalStorageKeysEnum } from '@shared/enums/local-storage.enum';
import {
  CognitoRefreshTokenResponse,
  RefreshTokenResponse,
} from '@shared/types/auth.type';
import { AuthService } from './auth.service';
import { CognitoAuthService } from './cognito-auth.service';
import { JwtService } from './jwt.service';
import { LocalStorageService } from './local-storage.service';
import { WebsocketService } from './websocket.service';

@Injectable({ providedIn: 'root' })
export class WebsocketInterceptorService {
  constructor(
    private readonly _authService: AuthService,
    private readonly _cognitoAuthService: CognitoAuthService,
    private readonly _destroyRef: DestroyRef,
    private readonly _localStorageService: LocalStorageService,
    private readonly _websocketService: WebsocketService,
    private readonly _router: Router,
    private readonly _jwtService: JwtService,
  ) {}

  get handleWsAuthorization() {
    /**
     *  Valida se é necessário atualizar o token de tempos em tempos
     */
    const refresh$ = new Subject<void>();

    this._intercept(refresh$);

    const onConnectSubscription = WebsocketService.onConnect
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(({ isNewConnection, oldConnectionWasClosed }) => {
        if (!isNewConnection) {
          return;
        }

        if (oldConnectionWasClosed) {
          this._intercept(refresh$);
        }
      });

    return refresh$.asObservable().pipe(
      debounceTime(100),
      switchMap(() => {
        return this._refreshToken();
      }),
      finalize(() => {
        onConnectSubscription.unsubscribe();
      }),
    );
  }

  private _intercept(refresh$: Subject<void>) {
    WebsocketService.client.io.on('ping', () => {
      if (!this._isToRefreshToken()) {
        return;
      }

      if (WebsocketService.client && WebsocketService.client.id) {
        refresh$.next();
      }
    });

    /**
     *  Valida se é necessário atualizar o token quando o usuário envia
     * alguma solicitação para o servidor.
     */
    WebsocketService.client.io.on('packet', () => {
      if (!this._isToRefreshToken()) {
        return;
      }

      if (WebsocketService.client && WebsocketService.client.id) {
        refresh$.next();
      }
    });
  }

  private _refreshToken() {
    if (!this._isToRefreshToken()) {
      return of();
    }

    const authServiceType = this._localStorageService.get(
      LocalStorageKeysEnum.AUTH_SERVICE_TYPE,
    );

    const refreshToken =
      (this._localStorageService.get(
        LocalStorageKeysEnum.REFRESH_TOKEN,
      ) as string) || '';

    let request$: Observable<
      RefreshTokenResponse | CognitoRefreshTokenResponse
    >;

    if (authServiceType === AuthServiceEnum.O_AUTH) {
      request$ = this._authService.refreshToken(refreshToken);
    } else {
      request$ = this._cognitoAuthService.refreshToken(refreshToken);
    }

    return request$.pipe(
      tap(() => {
        this._websocketService.createNewClient();
      }),
      catchError(() => {
        this._authService.logout();
        this._router.navigate(['/login']);

        return EMPTY;
      }),
    );
  }

  private _isToRefreshToken() {
    const accessToken =
      (this._localStorageService.get(
        LocalStorageKeysEnum.ACCESS_TOKEN,
      ) as string) || '';

    const jwtDecoded = this._jwtService.decode(accessToken);

    if (!jwtDecoded) return false;

    const now = new Date().getTime();
    /**
     *  Representa a margem segura para autalizar o token de acesso do usuário. Dessa forma,
     * é subtraído alguns minutos da expiração do token para que seja possível ter uma margem
     * segura para a geração de um novo token de acesso.
     */
    const safetyMarginTimeToRefresh = this._getSafetyMaginTimeToRefresh(
      jwtDecoded.exp,
    );
    /**
     *  Se o token expira 2024-10-10T10:00:00.000Z a validação será feita utilizando como
     * referência a data 2024-10-10T05:00:00.000Z, por exemplo, de froma a gerar uma margem de
     * segurança para que seja possível gerar um novo token de acesso antes do backend lançar
     * um erro "UNAUTHORIZED".
     */
    const expiration =
      new Date(Number(jwtDecoded.exp) * 1000).getTime() -
      safetyMarginTimeToRefresh;

    return now > expiration;
  }

  private _getSafetyMaginTimeToRefresh(exp: number) {
    const nowTime = new Date().getTime();
    const expirationTime = new Date(Number(exp) * 1000).getTime();

    return expirationTime - nowTime > 10 * 60 * 1000
      ? 5 * 60 * 1000
      : 3 * 60 * 1000;
  }
}
