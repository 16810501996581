import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs';
import { environment } from '@environment';
import { AuthServiceEnum } from '@shared/enums/auth-service.enum';
import { LocalStorageKeysEnum } from '@shared/enums/local-storage.enum';
import {
  CognitoAuthResponse,
  CognitoRefreshTokenResponse,
} from '@shared/types/auth.type';
import { CognitoJwtDecoded } from '@shared/types/jwt.type';
import { JwtService } from './jwt.service';
import { LocalStorageService } from './local-storage.service';
import { UserManagerService } from './user-manager.service';
import { WebsocketService } from './websocket.service';

@Injectable({
  providedIn: 'root',
})
export class CognitoAuthService {
  constructor(
    private readonly _http: HttpClient,
    private readonly _jwtService: JwtService,
    private readonly _localStorageService: LocalStorageService,
    private readonly _userManagerService: UserManagerService,
  ) {}

  auth(email: string, password: string) {
    return this._http
      .post<CognitoAuthResponse>(
        environment?.cognito?.url || '',
        {
          AuthParameters: {
            USERNAME: email,
            PASSWORD: password,
          },
          AuthFlow: 'USER_PASSWORD_AUTH',
          ClientId: environment?.cognito?.clientId || '',
        },
        {
          headers: {
            'X-Amz-Target': 'AWSCognitoIdentityProviderService.InitiateAuth',
            'Content-Type': 'application/x-amz-json-1.1',
          },
        },
      )
      .pipe(
        tap(({ AuthenticationResult: { IdToken, RefreshToken } }) => {
          this._localStorageService.set(
            LocalStorageKeysEnum.ACCESS_TOKEN,
            IdToken,
          );
          this._localStorageService.set(
            LocalStorageKeysEnum.REFRESH_TOKEN,
            RefreshToken,
          );

          this._localStorageService.set(
            LocalStorageKeysEnum.AUTH_SERVICE_TYPE,
            AuthServiceEnum.COGNITO,
          );
        }),
        tap(({ AuthenticationResult: { IdToken } }) => {
          const jwtDecoded = this._jwtService.decode(
            IdToken,
          ) as CognitoJwtDecoded;

          if (!jwtDecoded) {
            this._userManagerService.setUser(null);

            return;
          }

          this._userManagerService.setUser({
            email: jwtDecoded.email,
            id: jwtDecoded.sub,
            name: jwtDecoded.name,
            roles: jwtDecoded['cognito:groups'],
          });
        }),
      );
  }

  refreshToken(refreshToken: string) {
    return this._http
      .post<CognitoRefreshTokenResponse>(
        environment?.cognito?.url || '',
        {
          AuthFlow: 'REFRESH_TOKEN_AUTH',
          ClientId: environment?.cognito?.clientId || '',
          AuthParameters: {
            REFRESH_TOKEN: refreshToken,
          },
        },
        {
          headers: {
            'X-Amz-Target': 'AWSCognitoIdentityProviderService.InitiateAuth',
            'Content-Type': 'application/x-amz-json-1.1',
          },
        },
      )
      .pipe(
        tap(({ AuthenticationResult: { IdToken } }) => {
          this._localStorageService.set(
            LocalStorageKeysEnum.ACCESS_TOKEN,
            IdToken,
          );
        }),
      );
  }

  logout() {
    this._localStorageService.remove(LocalStorageKeysEnum.AUTH_SERVICE_TYPE);
    this._userManagerService.clearUserData();
    WebsocketService.client.disconnect();
  }
}
