import { Injectable } from '@angular/core';
import { jwtDecode } from 'jwt-decode';
import { CognitoJwtDecoded, JwtDecoded } from '@shared/types/jwt.type';

@Injectable({
  providedIn: 'root',
})
export class JwtService {
  decode(jwt: string): CognitoJwtDecoded | JwtDecoded {
    if (!jwt) return null;

    return jwtDecode(jwt);
  }
}
