import { NgModule } from '@angular/core';
import { ToastDefaultComponent } from './toast-default.component';
import { IconModule } from '../icon/icon.module';

@NgModule({
  declarations: [ToastDefaultComponent],
  imports: [IconModule],
  exports: [ToastDefaultComponent],
})
export class ToastDefaultModule {}
