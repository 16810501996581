import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { JwtService } from '@services/jwt.service';
import { LocalStorageService } from '@services/local-storage.service';
import { UserManagerService } from '@services/user-manager.service';
import { LocalStorageKeysEnum } from '@shared/enums/local-storage.enum';

export const loggedUser: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const router = inject(Router);
  const localStorageService = inject(LocalStorageService);
  const userManagerService = inject(UserManagerService);

  if (isLogged()) return true;

  localStorageService.set(LocalStorageKeysEnum.REDIRECT_URL, state.url);
  userManagerService.clearUserData();

  router.navigate(['login']);

  return false;
};

export const unLoggedUser: CanActivateFn = () => {
  const router = inject(Router);

  if (!isLogged()) return true;

  router.navigate(['dashboard']);

  return false;
};

function isLogged() {
  const userService = inject(UserManagerService);
  const jwtDecodeService = inject(JwtService);
  const localStorageService = inject(LocalStorageService);

  try {
    if (userService.user) return true;

    const accessToken = localStorageService.get(
      LocalStorageKeysEnum.ACCESS_TOKEN,
    );

    const jwtDecoded = jwtDecodeService.decode(accessToken);

    if (!accessToken || !jwtDecoded) return false;

    return true;
  } catch (err) {
    return false;
  }
}
