import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { User, UserRole } from '@models/user.model';
import { AuthServiceEnum } from '@shared/enums/auth-service.enum';
import { LocalStorageKeysEnum } from '@shared/enums/local-storage.enum';
import { CognitoJwtDecoded } from '@shared/types/jwt.type';
import { UserManager } from '@shared/types/user.type';
import { JwtService } from './jwt.service';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class UserManagerService {
  private readonly _user$ = new BehaviorSubject<UserManager | null>(null);
  private _user: UserManager | null = null;

  constructor(
    private readonly _jwtService: JwtService,
    private readonly _localStorageService: LocalStorageService,
  ) {}

  get user() {
    return this._user;
  }

  get listener() {
    return this._user$.asObservable();
  }

  setUser(user: Omit<UserManager, 'hasRole' | 'isResponsible'> | null) {
    this._user = !user
      ? null
      : {
          ...user,
          isResponsible: false,
          roles: user.roles || [],
          hasRole: this._hasRole(user.roles || []),
        };

    this._user$.next(this._user);
  }

  editUser(user: Partial<Omit<UserManager, 'hasRole'>>) {
    this._user = {
      ...this._user,
      ...(user as UserManager),
    };

    this._user$.next(this._user);
  }

  loadUserData() {
    const authService = this._localStorageService.get(
      LocalStorageKeysEnum.AUTH_SERVICE_TYPE,
    );

    let user: Omit<UserManager, 'hasRole'> | null = null;

    if (authService === AuthServiceEnum.O_AUTH) {
      user = this._cognitoUserData;
    } else if (authService === AuthServiceEnum.COGNITO) {
      user = this._oAuthUserData;
    } else {
      this.clearUserData();
    }

    this.setUser(user);
  }

  clearUserData() {
    this.setUser(null);

    this._localStorageService.remove(LocalStorageKeysEnum.ACCESS_TOKEN);
    this._localStorageService.remove(LocalStorageKeysEnum.REFRESH_TOKEN);
    this._localStorageService.remove(LocalStorageKeysEnum.USER);
  }

  private _hasRole(roles: string[]) {
    return (role: string) => {
      return roles.map(role => role.toUpperCase()).includes(role.toUpperCase());
    };
  }

  private get _oAuthUserData(): Omit<UserManager, 'hasRole'> | null {
    const authToken = this._localStorageService.get(
      LocalStorageKeysEnum.ACCESS_TOKEN,
    );

    const decodedToken = this._jwtService.decode(
      authToken,
    ) as CognitoJwtDecoded;

    if (!authToken || !decodedToken) return null;

    const {
      name,
      email,
      sub,
      'cognito:groups': roles,
    } = decodedToken as Exclude<CognitoJwtDecoded, null>;

    return {
      id: sub,
      isResponsible: false,
      email,
      name,
      roles,
    };
  }

  private get _cognitoUserData(): Omit<UserManager, 'hasRole'> | null {
    const userLocalStorage = this._localStorageService.get(
      LocalStorageKeysEnum.USER,
    ) as (User & { userRole?: UserRole }) | undefined;

    if (!userLocalStorage) return null;

    return {
      ...userLocalStorage,
      isResponsible: false,
      roles: userLocalStorage.userRole
        ? [userLocalStorage.userRole.userRoleType]
        : [],
    };
  }
}
