import { NgIf } from '@angular/common';
import { NgModule } from '@angular/core';
import { ToastComponent } from './toast.component';
import { IconModule } from '../icon/icon.module';
import { ToastDangerModule } from '../toast-danger/toast-danger.module';
import { ToastDefaultModule } from '../toast-default/toast-default.module';

@NgModule({
  declarations: [ToastComponent],
  imports: [IconModule, NgIf, ToastDangerModule, ToastDefaultModule],
  exports: [ToastComponent],
})
export class ToastModule {}
