/* eslint-disable max-len */
import { Component, OnInit } from '@angular/core';
import { UserManagerService } from '@services/user-manager.service';
import { WebsocketInterceptorService } from '@services/websocket-interceptor.service';
import { WebsocketService } from '@services/websocket.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private readonly _userManagerService: UserManagerService,
    private readonly _websocketInterceptor: WebsocketInterceptorService,
    private readonly _websocketService: WebsocketService,
  ) {}

  ngOnInit(): void {
    this._userManagerService.loadUserData();

    /**
     *  Valida se o usuário está logado ou não. Caso o usuário esteja
     * logado no sistema, a conexão é reestabelecida com o socket.
     */
    if (this._userManagerService.user) {
      this._websocketService.createNewClient();
      this._websocketInterceptor.handleWsAuthorization.subscribe();
    }
  }
}
